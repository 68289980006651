import { dateTimeService } from '~/utils/date-time.service';
import { type Plan } from '~db/types';

const isExpired = (plan: Plan) => {
  return (
    plan.type === 'TRIAL' &&
    plan.settings.expirationDate &&
    new Date(plan.settings.expirationDate) < new Date()
  );
};

const expiredIn = (plan: Plan) => {
  if (plan.type !== 'TRIAL') return null;
  if (!plan.settings.expirationDate) return null;
  return dateTimeService.relativeTime(new Date(plan.settings.expirationDate));
};

export const planUtils = {
  isExpired,
  expiredIn,
};

import { type MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type IAudit, auditActionTypes } from '~/server/services/audit/types';
import { appTableCellUtils } from '~/ui/table/table-cell.hook';
import { AutomationLinkIcon } from '../Automation/AutomationDisplay';
import { useAutomations } from '../Automation/automations.hook';
import { AuditChanges } from '../Card/Activities/Timeline/AuditChanges';
import { useCardTypes } from '../Cards/card-types.hook';
import { type FilterField } from '../Form/MultiFilters/MultiFilters';

export const useAuditColumns = () => {
  const { t: tAudit } = useTranslation('auditPage');
  const { automations, isFetching } = useAutomations();
  const { cardTypes } = useCardTypes();
  const fields: FilterField[] = useMemo(
    () => [
      {
        id: 'createdAt',
        system: 'createdAt',
        label: tAudit('Create At'),
        type: 'DATE',
      },
      {
        id: 'action',
        system: 'action',
        label: tAudit('Action'),
        type: 'MULTI_SELECT',
        options: auditActionTypes.map((item) => ({ value: item, label: item })),
      },
      {
        id: 'userId',
        system: 'userId',
        label: tAudit('User'),
        type: 'LOOKUP_USER',
      },
    ],
    [tAudit],
  );

  const columns: MRT_ColumnDef<IAudit>[] = useMemo(() => {
    return [
      appTableCellUtils.createColumn<IAudit>({
        accessorKey: 'createdAt',
        header: tAudit('Create At'),
        type: 'DATE_TIME',
      }),
      appTableCellUtils.createColumn<IAudit>({
        accessorKey: 'action',
        header: tAudit('Action'),
        type: 'MULTI_SELECT',
        Cell(props) {
          return tAudit(`action.${props.row.original.action}`);
        },
        field: {
          label: tAudit('Action'),
          system: 'action',
          type: 'MULTI_SELECT',
          options: auditActionTypes.map((item) => ({ value: item, label: tAudit(`action.${item}`) })),
        },
      }),
      appTableCellUtils.createColumn<IAudit>({
        accessorKey: 'userId',
        header: tAudit('User'),
        type: 'LOOKUP_USER',
      }),
      appTableCellUtils.createColumn<IAudit>({
        accessorKey: 'oldData',
        header: tAudit('Before'),
        type: 'JSON',
        enableSorting: false,
      }),
      appTableCellUtils.createColumn<IAudit>({
        accessorKey: 'newData',
        header: tAudit('After'),
        type: 'JSON',
        enableSorting: false,
      }),
      appTableCellUtils.createColumn<IAudit>({
        accessorKey: 'changes',
        header: tAudit('Changes'),
        type: 'JSON',
        enableSorting: false,
        Cell({ row }) {
          return <AuditChanges auditRecord={row.original} />;
        },
      }),

      // automation
      appTableCellUtils.createColumn<IAudit>({
        accessorKey: 'automationId',
        size: 50,
        header: tAudit('Automation'),
        type: 'TEXT',
        Cell({ row }) {
          const automation = automations.find((item) => item.id === row.original.automationId);

          return (
            <AutomationLinkIcon cardTypes={cardTypes} automation={automation} loading={isFetching} />
          );
        },
        enableSorting: false,
      }),
    ];
  }, [tAudit, automations, isFetching, cardTypes]);

  return { columns, fields };
};

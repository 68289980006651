import { useAuth } from '@clerk/nextjs';
import { api } from '~/trpc';
import { Permission, type PermissionAction, type PermissionType, type UserRole } from '~db/types';

type ExtendedUserRole = UserRole & { role: { permissions: Permission[] } };
export const useUserRole = (enabled = true) => {
  const { isSignedIn, orgId } = useAuth();

  const {
    data: userRole,
    isFetching,
    isFetched,
    ...rest
  } = api.rolesPermissions.getUserRole.useQuery(undefined, {
    enabled: Boolean(isSignedIn) && Boolean(orgId) && Boolean(enabled),
    staleTime: 1000 * 60 * 60 * 2,
    gcTime: 1000 * 60 * 60 * 24,
  });

  return {
    userRole,
    isFetching,
    isFetched,
    ...rest,
  };
};

export const usePermission = (type: PermissionType, action: PermissionAction) => {
  const { userRole, isLoading, ...rest } = useUserRole();

  const hasPermission = userRole?.role.permissions.find((p) => p.type === type)?.[action];

  return {
    hasPermission: Boolean(hasPermission),
    isLoadingPermission: isLoading,
    userRole,
    ...rest,
  };
};

export const hasPermission = (
  userRole: ExtendedUserRole,
  type: PermissionType,
  action: PermissionAction,
) => {
  return userRole.role.permissions.find((p) => p.type === type)?.[action];
};

import { Card, Group, Skeleton, Stack, Switch, Title } from '@mantine/core';
import {
  IconApi,
  IconArrowNarrowLeft,
  IconBell,
  IconCalendar,
  IconCalendarEvent,
  IconCalendarFilled,
  IconCheckbox,
  IconClick,
  IconDatabaseEdit,
  IconDatabasePlus,
  IconMail,
  IconMessage,
  IconNewSection,
  type IconSvg,
  IconTrash,
  IconUser,
} from '@tabler/icons-react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type AutomationAction,
  type AutomationTrigger,
} from '~/server/services/automation/automation.schema';
import { type PlainCardType } from '~/server/services/card-type/types';
import { type Automation } from '~db/types';
import { AppLink } from '../Shared/AppLink';
import { CardLink } from '../Shared/CardLink/CardLink';
import { AppLinkIcon } from '../Shared/Icon/AppLinkIcon';
import { useAutomation } from './Upsert/automation.hook';
import { automationUtils } from './automation.utils';

const AutomationLabel: React.FC<{ automation: Automation; cardType: PlainCardType }> = ({
  automation,
  cardType,
}) => {
  const { t } = useTranslation('automationsPage');
  const label = useMemo(
    () => automationUtils.generateLabel({ t, automation, cardType }),
    [automation, t],
  );
  return <Title order={5}>{label}</Title>;
};

const triggerToIcon: Record<AutomationTrigger['type'], typeof IconSvg> = {
  buttonClicked: IconClick,
  cardCreated: IconDatabasePlus,
  cardDeleted: IconTrash,
  dueDate: IconCalendar,
  emailReceived: IconMail,
  fieldChange: IconDatabaseEdit,
  userAssigned: IconUser,
  schedule: IconCalendarEvent,
};

const actionToIcon: Record<AutomationAction['type'], typeof IconSvg> = {
  changeField: IconDatabaseEdit,
  createMeeting: IconCalendarFilled,
  createTask: IconCheckbox,
  createCard: IconNewSection,
  email: IconMail,
  inAppNotification: IconBell,
  sms: IconMessage,
  webhook: IconApi,
};

const AutomationIcon: React.FC<{ automation: Automation }> = ({ automation }) => {
  const TriggerIcon = triggerToIcon[automation.trigger.type];
  const ActionIcon = actionToIcon[automation.actions[0]!.type];
  return (
    <Group gap={2}>
      <TriggerIcon size={24} />
      <IconArrowNarrowLeft size={16} />
      <ActionIcon size={24} />
    </Group>
  );
};

const LoadingAutomation: React.FC = () => {
  return (
    <Card shadow="sm" padding="xl" h={129}>
      <Group justify="space-between" align="center" h="100%">
        <Group>
          <Skeleton height={20} w={20} circle />
          <Skeleton height={20} circle />
        </Group>
        <Stack align="start" justify="stretch" style={{ flex: 1 }}>
          <Skeleton h={20} w={200} />
          <Skeleton h={20} w={400} />
        </Stack>
        <Group align="center">
          <Skeleton height={30} w={50} />
        </Group>
      </Group>
    </Card>
  );
};

export const LoadingAutomations: React.FC = () => {
  return (
    <Stack>
      {new Array(6).fill(null).map((_, i) => (
        <LoadingAutomation key={i} />
      ))}
    </Stack>
  );
};

export const AutomationDisplay: React.FC<{ automation: Automation; cardType: PlainCardType }> = ({
  automation,
  cardType,
  ...props
}) => {
  const { upsert, isUpdating } = useAutomation({ id: automation.id });
  const handleChangeStatus = useCallback<React.MouseEventHandler<HTMLDivElement>>(async () => {
    await upsert({ ...automation, status: automation.status === 'active' ? 'inactive' : 'active' });
  }, [upsert, automation]);

  return (
    <CardLink href={`/automations/${automation.id}`} padding="xl" {...props}>
      <Group justify="space-between">
        <Group>
          <AutomationIcon automation={automation} />
          <Stack>
            <AppLink href={`/automations/${automation.id}`}>{automation.description}</AppLink>
            <AutomationLabel automation={automation} cardType={cardType} />
          </Stack>
        </Group>
        <div onClick={(e) => e.stopPropagation()} onKeyUp={(e) => e.stopPropagation()}>
          <Switch
            style={{ pointerEvents: 'all' }}
            onClick={handleChangeStatus}
            disabled={isUpdating}
            size="lg"
            onLabel="ON"
            offLabel="OFF"
            checked={automation.status === 'active'}
          />
        </div>
      </Group>
    </CardLink>
  );
};

export const AutomationLinkIcon: React.FC<{
  automation?: Automation;
  loading?: boolean;
  cardTypes?: PlainCardType[];
}> = ({ automation, loading, cardTypes }) => {
  const { t } = useTranslation('automationsPage');
  if (!automation) return null;
  const Icon = triggerToIcon[automation.trigger.type];
  const cardType = cardTypes?.find((ct) => ct.slug === automation.cardTypeSlug);
  const label = cardType ? automationUtils.generateLabel({ t, automation, cardType }) : t('Automation');

  return (
    <AppLinkIcon
      Icon={Icon}
      loading={loading}
      href={`/automations/${automation.id}`}
      tooltip={automation.description ?? label}
    />
  );
};

import { Card, type CardProps, darken } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { type PropsWithChildren } from 'react';
import { useMantineColor } from '~/ui/hooks/mantine-color.hook';

type CardLinkProps = PropsWithChildren<
  CardProps & {
    href: string;
  }
>;

export const CardLink: React.FC<CardLinkProps> = ({ href, children, ...props }) => {
  const { hovered, ref } = useHover();
  const { color: hoverColor } = useMantineColor();

  return (
    <Card
      ref={ref}
      withBorder
      shadow={hovered ? 'xl' : 'none'}
      style={{
        height: '100%',
        cursor: 'pointer',
        transition: 'box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out',
        ...(hovered && hoverColor ? { borderColor: darken(hoverColor, 0.1) } : {}),
      }}
      onClick={(e) => {
        e.preventDefault();
        window.location.href = href;
      }}
      {...props}
    >
      {children}
    </Card>
  );
};

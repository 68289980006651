import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type IDuration } from './types';
import { durationUtils } from './utils';

export const useDurationOptions = ({
  endDate,
  date,
  initialDate,
  selectedId,
}: {
  endDate?: Date;
  date?: Date;
  initialDate?: Date;
  selectedId?: IDuration['id'];
}) => {
  const { t } = useTranslation('activitiesComponent');

  const durationOptions = useMemo(
    () =>
      durationUtils.durations.map(({ id, label }) => ({
        value: id,
        label: t(label),
      })),
    [t],
  );

  const option = useMemo(() => {
    if (!initialDate || !endDate) return durationOptions[0];

    const selected = durationUtils.getOptionId({
      endDate,
      date: initialDate,
    });

    if (selected) {
      return durationOptions.find((option) => option.value === selected);
    }
    return durationOptions[0];
  }, [endDate, initialDate, durationOptions]);

  const selectedDate = useMemo(() => {
    return durationUtils.getDate({
      optionId: selectedId ?? '30min',
      date: date ?? new Date(),
    });
  }, [date, selectedId]);

  return {
    durationOptions,
    option,
    selectedDate,
  };
};

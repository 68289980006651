import { useDebouncedValue } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { api } from '~/trpc';
import { ActivityType } from '~db/types';

export const activitiesAutoCompleteOptions = {
  gcTime: 60 * 60 * 1000,
  staleTime: 60 * 60 * 1000,
};

type ActivityAutoCompleteField = 'title' | 'description' | 'location';

export const useActivitiesAutoComplete = ({
  field,
  type,
}: { field: ActivityAutoCompleteField; type: ActivityType }) => {
  const [searchValue, setSearchValue] = useState('');

  const [debouncedValue] = useDebouncedValue(searchValue, 200);
  const { data = [], ...rest } = api.activities.autoComplete.useQuery(
    { query: debouncedValue, field, type },
    activitiesAutoCompleteOptions,
  );
  const options = useMemo(() => data.map((item) => ({ label: item, value: item })), [data]);

  return {
    ...rest,
    options,
    setSearchValue,
  };
};

import { Divider, Group, Paper, Stack } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useCallback } from 'react';
import { type Meeting as MeetingType } from '~/server/services/activity/types';
import { FloatingEditButton } from '../../../../Shared/FloatingEditButton';
import { Description } from '../Event/Description';
import { Duration } from '../Event/Duration/Duration';
import { FloatingActions } from '../Event/FloatingActions';
import { Mark } from '../Event/Mark';
import { Reminder } from '../Event/Reminder/Reminder';
import { SnoozeReminder } from '../Event/SnoozeReminder/SnoozeReminder';
import { Time } from '../Event/Time';
import { useUpsertMeetingStore } from './upsert-meeting.hook';

export const Meeting: React.FC<{ meeting: MeetingType; onAction?: () => void }> = ({
  meeting,
  onAction,
}) => {
  const { open } = useUpsertMeetingStore();
  const { hovered, ref } = useHover();

  const handleEditClick = useCallback(() => {
    open({ meeting });
  }, [meeting, open]);

  return (
    <Paper p="xs" radius="md" withBorder pos="relative" mih={40} ref={ref}>
      <Mark type={meeting.type} />
      <FloatingEditButton show={hovered} handleClick={handleEditClick} />
      <Group align="flex-start" gap="xs" wrap="nowrap">
        <Time dueDate={meeting.date} />
        <Stack gap={0}>
          <Reminder event={meeting} />
          <Duration meeting={meeting} />
        </Stack>
        <Divider orientation="vertical" />
        <Description title={meeting.title} description={meeting.description} />
      </Group>
      <FloatingActions show={hovered}>
        <SnoozeReminder activity={meeting} onClick={onAction} />
      </FloatingActions>
    </Paper>
  );
};

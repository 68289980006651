import { Text, Tooltip } from '@mantine/core';
import { IconBellRinging2 } from '@tabler/icons-react';
import { useMemo } from 'react';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { type ICreateMeeting, type ICreateTask } from '~/server/services/activity/types';
import { dateTimeService } from '~/utils/date-time.service';
import { useReminderOptions } from './reminder-options.hook';

export const Reminder: React.FC<{ event: ICreateMeeting | ICreateTask }> = ({ event }) => {
  const { reminder, date } = event;
  const { option } = useReminderOptions({ reminder, initialDate: date });

  const passed = useMemo(() => {
    if (!reminder) return null;
    return dateTimeService.isAfter(reminder, new Date());
  }, [reminder]);

  if (!reminder) return null;

  return (
    <Tooltip label={option?.label}>
      <Text>
        <AppIcon Icon={IconBellRinging2} color={passed ? undefined : 'gray'} size={'1rem'} />
      </Text>
    </Tooltip>
  );
};

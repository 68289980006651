import { isBoolean } from 'is-what';
import { useFeatureFlagEnabled } from 'posthog-js/react';
const FeatureFlags = ['automation', 'dashboard', 'finances', 'conversations', 'active-users'] as const;
export type FeatureFlag = (typeof FeatureFlags)[number];

export const useFeatureFlag = (featureFlag: FeatureFlag) => {
  const flagEnabled = useFeatureFlagEnabled(featureFlag);
  if (isBoolean(flagEnabled)) return flagEnabled;
  return true;
};

import { useActiveFeatureFlags } from 'posthog-js/react';

export const useFeatureFlags = () => {
  const featureFlags = useActiveFeatureFlags();
  return featureFlags ?? FeatureFlags;
};

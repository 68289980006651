import { useUser } from '@clerk/nextjs';
import { useMemo } from 'react';
import { clerkClientUtils } from '~/core/auth/clerk.utils';
import { useMemberships } from '~/ui/hooks/organization-memberships.hook';
import { emailGuestsTypes } from '~db/schema/activities';
import { type ActivityType, type Card } from '~db/types';

export const useDefaultCommunicationGuests = ({ type, card }: { type?: ActivityType; card?: Card }) => {
  const memberships = useMemberships();
  const { user } = useUser();
  const guests = useMemo<string[]>(() => {
    if (!card || !type) return [];
    const guests = new Set<string>();
    const isEmailType = emailGuestsTypes.some((emailType) => emailType === type);
    const owner = memberships?.data?.find((member) => member.publicUserData.userId === card?.owner);
    const ownerEmail = owner?.publicUserData.identifier;
    const ownerUserId = owner?.publicUserData.userId;
    const userEmail = clerkClientUtils.getUserPrimaryEmail(user);

    if (isEmailType) {
      if (userEmail) guests.add(userEmail);
      if (card.email) guests.add(card.email);
      if (ownerEmail) guests.add(ownerEmail);
    } else if (type === 'SMS') {
      if (card.phone) guests.add(card.phone);
    } else {
      if (card.email) guests.add(card.email);
      if (ownerUserId) guests.add(ownerUserId);
      if (user?.id) guests.add(user.id);
    }

    return Array.from(guests);
  }, [card?.email, card?.phone, memberships?.data, type, user?.id]);

  return { guests };
};

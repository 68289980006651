import { type InfiniteData } from '@tanstack/react-query';
import { type SortingState } from '@tanstack/table-core';
import { isFunction } from 'is-what';
import { type MRT_ColumnDef, type MRT_VisibilityState } from 'mantine-react-table';
import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { type ColumnVisibility, type ISearchResponse } from '~/server/services/types';
import { useQueryParams } from '~/ui/hooks/app.router.hook';
import { tableUtils } from '~/ui/table/table.utils';
import { MAX_RESPONSE_ITEMS } from '~/utils/search.config';
import { cardsTableViewsService } from '../../Components/Cards/CardsTable/cards-table-view.service';

export const useSyncTableParams = <
  Model extends Record<string, any>,
  Summary extends Record<string, number> = Record<string, number>,
>({
  columnVisibility: initialColumnVisibility,
  pageSize: _pageSize = MAX_RESPONSE_ITEMS,
  columns,
  initialSort = [],
}: {
  columns: MRT_ColumnDef<Model>[];
  pageSize?: number;
  columnVisibility?: MRT_VisibilityState;
  initialSort?: SortingState;
}) => {
  const { params, setQueryParams } = useQueryParams<{
    sort?: string;
    columns?: string;
    query?: string;
  }>();
  const [pageSize, setPageSize] = useState<number>(_pageSize);

  const sort = useMemo<SortingState>(() => tableUtils.extractSort(params) ?? initialSort, [params.sort]);

  const columnOrder = useMemo(() => {
    return tableUtils.extractColumns(params);
  }, [params.columns]);

  const [fullColumnOrder, setFullColumnOrder] = useState<string[]>(columnOrder);

  const columnVisibility = useMemo(() => {
    const extractedUrlColumns = tableUtils.extractColumns(params);

    if (!extractedUrlColumns.length) {
      return (
        initialColumnVisibility ??
        columns.reduce<MRT_VisibilityState>((acc, { accessorKey }) => {
          if (accessorKey) acc[accessorKey] = true;
          return acc;
        }, {})
      );
    }

    const updatedVisibility = { ...initialColumnVisibility };

    Object.keys(updatedVisibility).forEach((key) => {
      updatedVisibility[key] = extractedUrlColumns.includes(key);
    });

    return updatedVisibility;
  }, [params.columns, initialColumnVisibility, columns]);

  const handleSortChange = useCallback<Dispatch<SetStateAction<SortingState>>>(
    (action) => {
      const updated = isFunction(action) ? action(sort) : action;
      const sortUrl = tableUtils.formatSort(tableUtils.stateToSort(updated));
      setQueryParams({ sort: sortUrl });
    },
    [sort],
  );

  const handleGlobalFilterChange = useCallback((value?: string) => {
    setQueryParams({ query: value });
  }, []);

  const handleColumnVisibilityChange = useCallback<Dispatch<SetStateAction<ColumnVisibility>>>(
    (action) => {
      const updated = isFunction(action) ? action(columnVisibility) : action;

      const columnsConfig = cardsTableViewsService.generateColumnsConfig<Model>({
        columnVisibility: updated,
        columnOrder: fullColumnOrder,
        columns,
      });

      setQueryParams({ columns: tableUtils.formatColumns(columnsConfig) });
    },
    [params.columns, columns, fullColumnOrder, columnVisibility],
  );

  const handleColumnOrderChange = useCallback<Dispatch<SetStateAction<string[]>>>(
    (action) => {
      const updated = isFunction(action) ? action(fullColumnOrder) : action;
      const columnsConfig = cardsTableViewsService.generateColumnsConfig<Model>({
        columnVisibility,
        columnOrder: updated,
        columns,
      });

      setFullColumnOrder(updated);
      setQueryParams({ columns: tableUtils.formatColumns(columnsConfig) });
    },
    [columnVisibility, fullColumnOrder, columns],
  );

  const setPageLimit = useCallback((limit: number) => {
    setPageSize(limit);
  }, []);

  const getTotal = (data: InfiniteData<ISearchResponse<Model>> | undefined) =>
    data?.pages?.[0]?.total ?? 0;
  const getSummary = (data: InfiniteData<ISearchResponse<Model>> | undefined): Summary =>
    data?.pages?.[0]?.summary as Summary;

  useEffect(() => {
    // if initialSort is provided, but no sort is in the URL, set the initial sort in the URL
    if (initialSort.length && !params.sort) {
      handleSortChange(initialSort);
    }
  }, []);

  return {
    sort,
    columnVisibility,
    columnOrder,
    setSort: handleSortChange,
    setColumnVisibility: handleColumnVisibilityChange,
    setColumnOrder: handleColumnOrderChange,
    setGlobalFilter: handleGlobalFilterChange,
    globalFilter: params.query,
    fullColumnOrder,
    params,
    setQueryParams,
    pageSize,
    setPageLimit,
    getTotal,
    getSummary,
  };
};

import { useCallback, useMemo } from 'react';
import { type FilterField } from '~/Components/Form/MultiFilters/MultiFilters';
import { type IFilter } from '~/server/services/types';
import { useQueryParams } from '~/ui/hooks/app.router.hook';
import { tableUtils } from '~/ui/table/table.utils';
import { filtersUtils } from '~/utils/filters.utils';
import { type Card } from '~db/types';
import { useCardsTableViews } from './cards-table-views.hook';

export const useMultiFilters = <Model extends Record<string, unknown> = Card>(
  customFields?: FilterField[],
) => {
  const { setQueryParams, params } = useQueryParams<{
    filters: string;
    sort: string;
  }>();
  const { fields } = useCardsTableViews();

  const updateFilter = useCallback(
    (updatedFilters: IFilter<Model>) => {
      setQueryParams({ filters: tableUtils.formatFilters(updatedFilters) });
    },
    [setQueryParams],
  );

  const filters = useMemo<IFilter<Model>>(() => {
    const urlFilters = tableUtils.extractFilters(params.filters, customFields ?? fields) ?? {};
    return filtersUtils.parse(urlFilters as IFilter<Model>, customFields ?? fields);
  }, [params, fields, customFields]);

  return {
    filters,
    updateFilter,
  };
};

import { Badge } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useLocale } from '~/ui/hooks/locale.hook';
import { type EventTypes } from '~db/types';

export const Mark: React.FC<{ type: EventTypes }> = ({ type }) => {
  const { t } = useTranslation('activitiesComponent');
  const { alignContent } = useLocale();
  return (
    <Badge
      size="xs"
      color={type === 'TASK' ? 'blue' : 'violet'}
      style={{
        transform: 'rotate(90deg)',
        transformOrigin: 'center center',
        [alignContent]: -5,
      }}
      pos="absolute"
      top={'2.3rem'}
    >
      {t(type)}
    </Badge>
  );
};

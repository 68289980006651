import { dateTimeService } from '~/utils/date-time.service';
import { type IDuration } from './types';

type IGetDurationOption = {
  endDate: Date;
  date: Date;
};

export const durations: IDuration[] = [
  {
    id: '15min',
    label: '15 minutes',
    value: 15 * 60 * 1000,
  },
  {
    id: '30min',
    label: '30 minutes',
    value: 30 * 60 * 1000,
  },
  {
    id: '45min',
    label: '45 minutes',
    value: 45 * 60 * 1000,
  },
  {
    id: '1hour',
    label: '1 hour',
    value: 1 * 60 * 60 * 1000,
  },
  {
    id: '1hour15min',
    label: '1 hour and 15 minutes',
    value: 1 * 75 * 60 * 1000,
  },
  {
    id: '1hour30min',
    label: '1 hour and 30 minutes',
    value: 1 * 90 * 60 * 1000,
  },
  {
    id: '1hour45min',
    label: '1 hour and 45 minutes',
    value: 1 * 105 * 60 * 1000,
  },
  {
    id: '2hours',
    label: '2 hours',
    value: 2 * 60 * 60 * 1000,
  },
];

const getOptionId = ({ endDate, date }: IGetDurationOption): IDuration['id'] => {
  const milliseconds = dateTimeService.getDiffInMilliseconds(endDate, date);
  const option = durations.find((option) => option.value === milliseconds);
  return option?.id ?? '30min';
};

type IGetDurationDate = {
  optionId: IDuration['id'];
  date: Date;
};

const getDate = ({ optionId, date }: IGetDurationDate): Date => {
  const option = durations.find((option) => option.id === optionId);
  const defaultDate = dateTimeService.addMilliseconds(date, 30 * 60 * 1000);
  if (!option?.value) return defaultDate;
  return dateTimeService.addMilliseconds(date, option.value);
};

export const durationUtils = {
  durations,
  getDate,
  getOptionId,
};

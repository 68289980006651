import { createInsertSchema } from 'drizzle-zod';
import { z } from 'zod';
import { type ISearchRequest, type ISearchResponse } from '~/server/services/types';
import { auditTable } from '~db/schema';
import { type Audit } from '../../../../db/types';

export type IAudit = Audit & {
  action: IAuditAction;
  changes: { key: string; from: unknown; to: unknown }[];
};
export type IAuditAction = (typeof auditActionTypes)[number];

export const auditActionTypes = [
  // Card Types
  'CREATE_CARD_TYPE',
  'UPDATE_CARD_TYPE',
  'CREATE_FIELDS',
  'DELETE_CARD_TYPE',

  // Card Type Fields
  'DELETE_CARD_FIELD',
  'UPDATE_CARD_FIELD',

  // Card type layouts
  'CREATE_CARD_LAYOUT',
  'UPDATE_CARD_LAYOUT',
  'DELETE_CARD_LAYOUT',

  // Cards
  'CREATE_CARD',
  'UPLOAD_CARDS',
  'UPDATE_CARD',
  'DELETE_CARD',
  'DELETE_CARDS',
  'UPDATE_CARD_RELATION',

  // Activities
  'CREATE_EVENT',
  'UPDATE_EVENT',
  'CREATE_TASK',
  'UPDATE_TASK',
  'CREATE_COMMUNICATION',
  'UPDATE_COMMUNICATION',
  'DELETE_ACTIVITY',

  // AI
  'AI_GENERATE_DESCRIPTION',

  // Roles and Permissions
  'CREATE_ROLE',
  'UPDATE_ROLE',
  'DELETE_ROLE',
  'UPDATE_USER_ROLE',
  'CREATE_USER_ROLE',
  'DELETE_USER_ROLE',

  // Card Table Views
  'CREATE_CARDS_TABLE_VIEW',
  'UPDATE_CARDS_TABLE_VIEW',
  'DELETE_CARDS_TABLE_VIEW',

  // Organizations
  'UPDATE_PLAN',
  'CREATE_ORGANIZATION',
  'UPDATE_ORGANIZATION_ACCESS',
  'INVITE_USER',

  // Storage
  'DELETE_FILE',
  'UPLOAD_FILE',

  // API Tokens
  'CREATE_API_TOKEN',
  'DELETE_API_TOKEN',

  // Admin Console
  'DELETE_ORG',
  'IMPERSONATE_USER',

  // Automations
  'CREATE_AUTOMATION',
  'UPDATE_AUTOMATION',
  'DELETE_AUTOMATION',
  'EXECUTE_AUTOMATION',

  // Finance Documents
  'CREATE_FINANCE_DOCUMENT',
  'UPDATE_FINANCE_DOCUMENT',
  'DELETE_FINANCE_DOCUMENT',

  // SMS
  'SEND_SMS',
] as const;

// unknown record or array of unknown records
const dataSchema = z.record(z.unknown());

export const auditSchema = createInsertSchema(auditTable, {
  oldData: dataSchema,
  newData: dataSchema,
  action: z.enum(auditActionTypes),
});

export type ICreateAuditInput = z.input<typeof auditSchema>;
export type ISearchAuditInput = ISearchRequest<Audit> & { userId: string };
export type ISearchAuditResponse = ISearchResponse<IAudit>;

import { dateTimeService } from '~/utils/date-time.service';
import { type IReminder } from './types';

type IGetReminderOption = {
  reminder: Date | null | undefined;
  date: Date;
};

const reminders: IReminder[] = [
  {
    id: 'none',
    label: 'None',
    value: null,
  },
  {
    id: '5min',
    label: '5 minutes before',
    value: 5 * 60 * 1000,
  },
  {
    id: '10min',
    label: '10 minutes before',
    value: 10 * 60 * 1000,
  },
  {
    id: '15min',
    label: '15 minutes before',
    value: 15 * 60 * 1000,
  },
  {
    id: '30min',
    label: '30 minutes before',
    value: 30 * 60 * 1000,
  },
  {
    id: '45min',
    label: '45 minutes before',
    value: 45 * 60 * 1000,
  },
  {
    id: '1hour',
    label: '1 hour before',
    value: 1 * 60 * 60 * 1000,
  },
  {
    id: '1day',
    label: '1 day before',
    value: 1 * 24 * 60 * 60 * 1000,
  },
];

const getOptionId = ({ reminder, date }: IGetReminderOption): IReminder['id'] => {
  const milliseconds = dateTimeService.getDiffInMilliseconds(date, reminder ?? date);
  const option = reminders.find((option) => option.value === milliseconds);
  return option?.id ?? 'none';
};

type IGetReminderDate = {
  optionId: IReminder['id'];
  date: Date;
};

const getDate = ({ optionId, date }: IGetReminderDate): Date | null => {
  const option = reminders.find((option) => option.id === optionId);
  if (!option?.value) return null;
  return dateTimeService.subtractMilliseconds(date, option.value);
};

export const reminderUtils = {
  reminders,
  getDate,
  getOptionId,
};

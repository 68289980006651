import { useAuth } from '@clerk/nextjs';
import { keepPreviousData } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { type IAudit } from '~/server/services/audit/types';
import { api } from '~/trpc';
import { useSyncTableParams } from '~/ui/table/sync-table-pramas.hook';
import { getNextPageParam, tableUtils } from '~/ui/table/table.utils';
import { useMultiFilters } from '../Cards/hooks/filters.hook';
import { useAuditColumns } from './audit-columns.hook';

export const useAudit = ({ limit, cardId }: { limit?: number; cardId?: string } = {}) => {
  const { isSignedIn, isLoaded } = useAuth();
  const { fields, columns } = useAuditColumns();
  const { filters, updateFilter } = useMultiFilters<IAudit>(fields);

  const { sort, setSort, setPageLimit, pageSize, getTotal } = useSyncTableParams<IAudit>({
    columns,
    pageSize: limit,
    initialSort: [{ id: 'createdAt', desc: true }],
  });

  const input = useMemo(() => {
    return {
      limit: pageSize,
      filter: filters,
      sort: tableUtils.stateToSort<IAudit>(sort),
      cardId,
    };
  }, [filters, sort, pageSize, cardId]);

  const { data, isLoading, fetchNextPage, isFetching, isFetchingNextPage } =
    api.audit.search.useInfiniteQuery(input, {
      enabled: isSignedIn && isLoaded,
      placeholderData: keepPreviousData,
      getNextPageParam,
    });

  const items = useMemo(
    () =>
      data?.pages.reduce<IAudit[]>((res, curr) => {
        res.push(...curr.results);
        return res;
      }, []) ?? [],
    [data?.pages],
  );

  const getNextPage = useCallback(() => {
    void fetchNextPage();
  }, [fetchNextPage]);

  const totalAudits = getTotal(data);

  return {
    items,
    isLoadingAudit: isLoading,
    isFetchingNextPage,
    isAuditFetching: isFetching || isFetchingNextPage,
    hasNextPage: items.length < totalAudits,
    totalAudits,
    getNextPage,
    sort,
    setSort,
    updateFilter,
    filters,
    setPageLimit,
  };
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type IReminder } from './types';
import { reminderUtils } from './utils';

export const useReminderOptions = ({
  reminder,
  date,
  initialDate,
  selectedId,
}: {
  reminder?: Date | null;
  date?: Date;
  initialDate?: Date;
  selectedId?: IReminder['id'];
}) => {
  const { t } = useTranslation('activitiesComponent');

  const reminderOptions = useMemo(
    () =>
      reminderUtils.reminders.map(({ id, label }) => ({
        value: id,
        label: t(label),
      })),
    [t],
  );

  const option = useMemo(() => {
    if (!initialDate || !reminder) return reminderOptions[0];

    const selected = reminderUtils.getOptionId({
      reminder,
      date: initialDate,
    });

    if (selected) {
      return reminderOptions.find((option) => option.value === selected);
    }
    return reminderOptions.find((option) => option.value === '15min');
  }, [reminder, initialDate, reminderOptions]);

  const selectedDate = useMemo(() => {
    if (!selectedId || !date) return null;
    return reminderUtils.getDate({
      optionId: selectedId,
      date,
    });
  }, [date, selectedId]);

  return {
    reminderOptions,
    option,
    selectedDate,
  };
};

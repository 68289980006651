import { Button, Card, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconSquare } from '@tabler/icons-react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { type ICreateTask } from '~/server/services/activity/types';
import { api } from '~/trpc';
import { useUpsertTaskStore } from './upsert-task.hook';

export const FloatingCheckedMark: React.FC<{
  task: ICreateTask;
  isHover: boolean;
}> = ({ task, isHover }) => {
  const { t } = useTranslation('activitiesComponent');

  const { cardId } = useUpsertTaskStore((state) => ({
    cardId: state.cardId,
  }));

  const utils = api.useUtils();

  const { mutate: upsert, isPending: isLoading } = api.tasks.upsert.useMutation({
    onSuccess: async () => {
      notifications.show({
        title: t('Task updated'),
        message: t('Task updated successfully'),
        color: 'green',
      });
      await Promise.all([
        utils.activities.search.invalidate(),
        utils.cards.search.invalidate({ columnVisibility: { lastActivity: true } }),
      ]);
    },
    onError: (error) => {
      notifications.show({
        title: t('Error'),
        message: error.message,
        color: 'red',
      });
    },
  });

  const handleClick = useCallback(() => {
    upsert({
      cardId,
      ...task,
      reminder: null,
      done: false,
    });
  }, [upsert, cardId, task]);

  const buttonContent = useMemo(() => {
    if (isHover) {
      return {
        icon: <AppIcon Icon={IconSquare} size={'1rem'} />,
        text: t('Mark as undone'),
        ariaLabel: 'Mark task as undone',
      };
    }
    return {
      icon: <AppIcon Icon={IconCheck} color="green" size="1rem" />,
      text: t('Done'),
      ariaLabel: 'Task is done',
    };
  }, [isHover, t]);

  if (!task.done) return null;

  return (
    <Card p={2} pos="absolute" left="1rem" bottom="1rem">
      <Button
        size="xs"
        onClick={handleClick}
        loading={isLoading}
        variant="transparent"
        color="grey"
        pb={0}
        leftSection={buttonContent.icon}
        aria-label={buttonContent.ariaLabel}
      >
        <Text size="xs">{buttonContent.text}</Text>
      </Button>
    </Card>
  );
};

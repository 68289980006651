import { useAuth } from '@clerk/nextjs';
import { notifications } from '@mantine/notifications';
import { useIsMutating } from '@tanstack/react-query';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { planUtils } from '~/Components/Settings/PlanAndBilling/plan.utils';
import { useUserRole } from '~/Components/Settings/RolesPermissions/permissions.hook';
import { api } from '~/trpc';
import { useAppRouter } from './app.router.hook';

export const useSettings = () => {
  const { t } = useTranslation('settingsPage');
  const { isSignedIn, orgId } = useAuth();
  const { isMarketingPage } = useAppRouter();
  const router = useRouter();

  const pathname = usePathname();
  const isCreatingOrg = useIsMutating({ mutationKey: ['createOrganization'] });
  const enabled = Boolean(isSignedIn) && !isMarketingPage && Boolean(orgId) && isCreatingOrg === 0;

  const { data: userSettings, isLoading: isLoadingUserSettings } = api.settings.getUserSettings.useQuery(
    undefined,
    {
      enabled,
      staleTime: 1000 * 60 * 60 * 2,
      gcTime: 1000 * 60 * 60 * 24,
    },
  );

  const { data: settings, isLoading: isLoadingSettings } = api.settings.getAccountSettings.useQuery(
    undefined,
    {
      enabled,
      staleTime: 1000 * 60 * 60 * 2,
      gcTime: 1000 * 60 * 60 * 24,
    },
  );
  const { data: plan, isLoading: isLoadingPlan } = api.plans.get.useQuery(undefined, {
    gcTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
    enabled,
  });

  const { userRole, isFetching, isFetched } = useUserRole(!isMarketingPage && Boolean(settings?.id));

  const isAdminConsole = useMemo(() => {
    const adminConsolePermission = userRole?.role.permissions.find(
      (permission) => permission.type === 'ADMIN_CONSOLE',
    );
    return Boolean(adminConsolePermission?.read);
  }, [userRole]);

  useEffect(() => {
    if (pathname === '/settings/setup') return;
    if (isFetching || !isFetched) return;
    if (userRole) return;
    router.push('/settings/setup');
  }, [userRole, isFetching, isFetched, pathname, router]);

  useEffect(() => {
    // if plan is trial and expired redirect to billing
    if (isLoadingPlan || !plan) return;
    const isExpired = planUtils.isExpired(plan);
    if (!isExpired) return;
    notifications.show({
      title: t('Your trial plan has expired'),
      message: t('Please contact support to continue using the app'),
      color: 'yellow',
    });
    router.push('/settings/plan-and-billing');
  }, [isLoadingPlan, plan, router]);

  return {
    isAdminConsole,
    isLoadingSettings,
    settings: settings?.settings,
    userSettings: userSettings?.settings,
    isLoadingUserSettings,
    userRole,
    isLoadingPlan,
    plan,
    createdAt: settings?.createdAt,
  };
};

const generateFieldTemplate = (system: string) => {
  return `{{${system}}}`;
};

const isFieldTemplate = (value: string) => {
  return value.startsWith('{{') && value.endsWith('}}');
};

const extractFieldTemplate = (value: string) => {
  if (!isFieldTemplate(value)) {
    return null;
  }
  return value.slice(2, -2);
};

export const automationHelpers = {
  generateFieldTemplate,
  isFieldTemplate,
  extractFieldTemplate,
};

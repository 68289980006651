import { Button, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { type ICreateTask } from '~/server/services/activity/types';
import { api } from '~/trpc';
import { useUpsertTaskStore } from './upsert-task.hook';

export const CheckAsDone: React.FC<{ task: ICreateTask; onClick?: () => void }> = ({
  task,
  onClick,
}) => {
  const { t } = useTranslation('activitiesComponent');
  const { cardId } = useUpsertTaskStore((state) => ({
    cardId: state.cardId,
  }));

  const utils = api.useUtils();

  const { mutate: upsert, isPending: isLoading } = api.tasks.upsert.useMutation({
    onSuccess: async () => {
      notifications.show({
        title: t('Task updated'),
        message: t('Task updated successfully'),
        color: 'green',
      });
      onClick?.();
      await utils.activities.search.invalidate();
      await utils.cards.search.invalidate({ columnVisibility: { lastActivity: true } });
    },
    onError: (error) => {
      notifications.show({
        title: t('Error'),
        message: error.message,
        color: 'red',
      });
    },
  });

  const handleClick = useCallback(() => {
    upsert({
      cardId,
      ...task,
      reminder: null,
      done: true,
    });
  }, [upsert, cardId, task]);

  if (task.done) return null;

  return (
    <Button size="xs" onClick={handleClick} loading={isLoading} variant="subtle" color="green">
      <Text size="xs">{t('Mark as done')}</Text>
    </Button>
  );
};

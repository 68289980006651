import { z } from 'zod';
import { taskSchema } from '~/server/services/activity/types';
import { reminderIds } from '../Event/Reminder/types';

export const taskFormSchema = taskSchema.extend({
  reminderTimeBefore: z.enum(reminderIds),
  assignedTo: z.string().min(1),
  date: z.date(),
});

export type IFormCreateTask = z.infer<typeof taskFormSchema>;

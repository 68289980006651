import { useTranslation } from 'react-i18next';
import { FormSelectCreate } from '~/Components/Form/Select/Create';
import { useActivitiesAutoComplete } from './activities-autocomplete.hook';

export const MeetingLocation: React.FC = () => {
  const { t } = useTranslation('activitiesComponent');
  const { options, isLoading, setSearchValue } = useActivitiesAutoComplete({
    field: 'location',
    type: 'MEETING',
  });

  return (
    <FormSelectCreate
      name="location"
      clearable
      withinPortal={false}
      data={options}
      loading={isLoading}
      searchable
      creatable
      onSearchChange={setSearchValue}
      label={`${t('Where')}?`}
    />
  );
};

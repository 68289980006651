import { type ComboboxItem } from '@mantine/core';
import { type TFunction } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  type AutomationAction,
  type AutomationCondition,
  type AutomationTrigger,
} from '~/server/services/automation/automation.schema';
import { automationHelpers } from '~/server/services/automation/automation.utils';
import { type PlainCardType } from '~/server/services/card-type/types';
import { dateTimeService } from '~/utils/date-time.service';
import { type Automation } from '~db/types';
import { type AutomationFormValues } from './Upsert/schema';

export const useAutomationTriggerOptions = (): ComboboxItem[] => {
  const { t } = useTranslation('automationsPage');
  return [
    { value: 'fieldChange', label: t('status change') },
    // { value: 'userAssigned', label: t('user assigned'), disabled: true },
    // { value: 'buttonClicked', label: t('button clicked'), disabled: true },
    // { value: 'emailReceived', label: t('email received'), disabled: true },
    { value: 'cardCreated', label: t('card created') },
    { value: 'cardDeleted', label: t('card deleted') },
    { value: 'schedule', label: t('every time period'), disabled: true },
    // { value: 'dueDate', label: t('date arrives'), disabled: true },
  ];
};
export const useAutomationActionOptions = (): ComboboxItem[] => {
  const { t } = useTranslation('automationsPage');
  const formMethods = useFormContext<AutomationFormValues>();
  const triggerType = formMethods.watch('trigger.type');

  return [
    // { value: 'inAppNotification', label: t('notify'), disabled: true },
    { value: 'changeField', label: t('change field'), disabled: triggerType === 'cardDeleted' },
    { value: 'email', label: t('send email') },
    // { value: 'createCard', label: t('create card'), disabled: true },
    // { value: 'createMeeting', label: t('create meeting'), disabled: true },
    { value: 'createTask', label: t('create task') },
    { value: 'sms', label: t('send SMS'), disabled: true },
    { value: 'webhook', label: t('call webhook') },
  ];
};

type TriggerToLabel<T extends AutomationTrigger> = {
  [K in T['type']]: (
    t: TFunction<'automationsPage'>,
    trigger: Extract<T, { type: K }>,
    cardType: PlainCardType,
  ) => string;
};

const mapTriggerToLabel: TriggerToLabel<AutomationTrigger> = {
  fieldChange: (t, { params }, cardType) => {
    const field = cardType.fields.find((f) => f.system === params.field);
    const fieldLabel = field?.label ?? params.field;
    const from = params.from ? field?.options?.find((o) => o.value === params.from)?.label : params.from;
    const to = params.to ? field?.options?.find((o) => o.value === params.to)?.label : params.to;
    if (params.from && params.to) {
      return `${t('When field')} "${fieldLabel}" ${t('changes from')} ${from} ${t(`to`)} ${to}`;
    }
    if (params.from) {
      return `${t('When field')} "${fieldLabel}" ${t('changes from')} ${from}`;
    }
    if (params.to) {
      return `${t('When field')} "${fieldLabel}" ${t('changes to')} ${to}`;
    }
    return `${t('When field')} "${fieldLabel}" ${t('changes')}`;
  },
  userAssigned: (t) => `${t('When user assigned')}`,
  buttonClicked: (t) => `${t('When button clicked')}`,
  emailReceived: (t) => `${t('When email received')}`,
  cardCreated: (t) => `${t('When card created')}`,
  cardDeleted: (t) => `${t('When card deleted')}`,
  schedule: (t, { params }) => {
    if (params.type === 'daily') {
      return `${t('Every')} ${t('day')} ${t('at')} ${params.params.time}`;
    }
    if (params.type === 'weekly') {
      return `${t('Every')} ${t('week')} ${t('at')} ${params.params.time} ${t('on')} ${params.params.daysOfWeek
        .map((d) => {
          const day = dateTimeService.numberToDay(d);
          if (!day) return d;
          return t(day);
        })
        .join(`, `)}`;
    }
    if (params.type === 'monthly') {
      return `${t('Every')} ${t('month')} ${t('at')} ${params.params.time} ${t('on the')} ${params.params.daysOfMonth.map((d) => `${d}th`).join(`, `)}`;
    }
    return `schedule not supported`;
  },
  dueDate: (t, { params }) => `${t('On due date')} ${dateTimeService.format(params.date)}`,
};

type ActionToLabel<T extends AutomationAction> = {
  [K in T['type']]: (
    t: TFunction<'automationsPage'>,
    action: Extract<T, { type: K }>,
    cardType: PlainCardType,
  ) => string;
};

const mapActionToLabel: ActionToLabel<AutomationAction> = {
  changeField: (t, { params }, cardType) => {
    const field = cardType.fields.find((f) => f.system === params.field);
    const fieldLabel = field?.label ?? params.field;
    const value = field?.options?.find((o) => o.value === params.value)?.label ?? params.value;
    return `${t('change field')} "${fieldLabel}" ${t('to')} "${value}"`;
  },
  createMeeting: (t) => t('create meeting'),
  createTask: (t) => t('create task'),
  email: (t, { params }, type) =>
    `${t('send email to')} ${params.to
      .map((to) => {
        const systemField = automationHelpers.extractFieldTemplate(to);
        if (systemField) return type.name;
        return to;
      })
      .join(` ${t('and')} `)}`,
  inAppNotification: (t, { params }) => `${t('notify')} ${params.userIds.join(` ${t('and')} `)}`,
  sms: (t, { params }, type) =>
    `${t('send SMS to')} ${params.to
      .map((to) => {
        const systemField = automationHelpers.extractFieldTemplate(to);
        if (systemField) return type.name;
        return to;
      })
      .join(` ${t('and')} `)}`,
  webhook: (t, { params }) => `${t('call webhook')}: [${params.method}] ${params.url}`,
  createCard: (t, { params }, cardType) => {
    return `${t('create')} ${cardType.name} ${t('with')} ${Object.entries(params.input)
      .map(([system, value]) => {
        const field = cardType.fields.find((f) => f.system === system);
        const label = field?.label ?? system;
        const valueLabel = field?.options?.find((o) => o.value === value)?.label ?? value;
        return `${label}: ${valueLabel}`;
      })
      .join(', ')}`;
  },
};

const conditionToLabel = (condition: AutomationCondition): string => {
  return '';
};

const generateLabel = ({
  t,
  automation,
  cardType,
}: {
  t: TFunction<'automationsPage'>;
  automation: Automation;
  cardType: PlainCardType;
}) => {
  if (!automation) {
    return '';
  }
  const { trigger, conditions, actions } = automation;
  const parts = [];
  if (trigger) {
    parts.push(mapTriggerToLabel[trigger.type](t, trigger as never, cardType));
  }
  if (conditions?.length) {
    parts.push(` ${conditions.map((c) => conditionToLabel(c)).join(', ')}`);
  }
  if (actions) {
    parts.push(
      `, ${actions.map((action) => mapActionToLabel[action.type](t, action as never, cardType)).join(', ')}`,
    );
  }
  return parts.join('');
};

export const automationUtils = {
  generateLabel,
};

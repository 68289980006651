import { notifications } from '@mantine/notifications';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type ICreateMeeting,
  type ICreateTask,
  type Meeting,
  type Task,
} from '~/server/services/activity/types';
import { api } from '~/trpc';

export const useSnoozeReminder = ({
  activity,
  onSuccessCallback,
}: {
  activity?: Meeting | Task;
  onSuccessCallback?: () => void;
}) => {
  const { t } = useTranslation('activitiesComponent');
  const utils = api.useUtils();

  const isSnoozable = useMemo(() => {
    if (!activity) return;
    if (activity.reminder === null) return false;
    return activity.date.getTime() > Date.now() + 5 * 60 * 1000;
  }, [activity]);

  const { mutate: updateTask, isPending: isUpdateTask } = api.tasks.upsert.useMutation({
    onSuccess: async () => {
      notifications.show({
        title: t('Task updated'),
        message: t('Task updated successfully'),
        color: 'green',
      });
      await utils.activities.search.invalidate();
      await utils.cards.search.invalidate({ columnVisibility: { lastActivity: true } });
    },
    onError: (error) => {
      notifications.show({
        title: t('Error'),
        message: error.message,
        color: 'red',
      });
    },
  });

  const { mutate: updateMeeting, isPending: isUpdateMeeting } = api.meetings.upsert.useMutation({
    onSuccess: async () => {
      notifications.show({
        title: t('Meeting updated'),
        message: t('Meeting updated successfully'),
        color: 'green',
      });
      await utils.activities.search.invalidate();
      await utils.cards.search.invalidate({ columnVisibility: { lastActivity: true } });
    },
    onError: (error) => {
      notifications.show({
        title: t('Error'),
        message: error.message,
        color: 'red',
      });
    },
  });

  const removeReminder = useCallback(() => {
    onSuccessCallback?.();
    if (!activity) return;
    if (activity.type === 'TASK') {
      const task = activity as ICreateTask;
      updateTask({
        ...task,
        reminder: null,
      });
    }
    if (activity.type === 'MEETING') {
      const meeting = activity as ICreateMeeting;
      updateMeeting({
        ...meeting,
        reminder: null,
      });
    }
  }, [updateMeeting, updateTask, activity, onSuccessCallback]);

  const snooze = useCallback(() => {
    onSuccessCallback?.();
    if (!activity) return;
    const newReminder = new Date(activity.date.getTime() - 5 * 60 * 1000);

    if (activity.type === 'TASK') {
      const task = activity as ICreateTask;
      updateTask({
        ...task,
        reminder: newReminder,
      });
    }
    if (activity.type === 'MEETING') {
      const meeting = activity as ICreateMeeting;
      updateMeeting({
        ...meeting,
        reminder: newReminder,
      });
    }
  }, [updateMeeting, updateTask, activity, onSuccessCallback]);

  return {
    isSnoozable,
    removeReminder,
    snooze,
    isUpdating: isUpdateTask || isUpdateMeeting,
  };
};

import { Card, Group } from '@mantine/core';
import { useLocale } from '~/ui/hooks/locale.hook';

export const FloatingActions: React.FC<{ show: boolean; children: React.ReactNode }> = ({
  show = false,
  children,
}) => {
  const { alignContent } = useLocale();
  return (
    <Card
      p={5}
      pos="absolute"
      bottom={'0.5rem'}
      opacity={show ? 1 : 0}
      style={{
        transition: 'opacity 0.1s ease-in-out',
        [alignContent]: '1rem',
      }}
    >
      <Group gap="xs" w="100%">
        {children}
      </Group>
    </Card>
  );
};

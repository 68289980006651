import { Group, Stack, Text } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardTypes } from '~/Components/Cards/card-types.hook';
import { FieldDisplayComponent } from '~/Components/Settings/CardTypes/Fields/FieldsDisplay';
import { type IAudit } from '~/server/services/audit/types';
import { type FieldProps } from '~/server/services/templates/fields';

export const AuditChanges: React.FC<{ auditRecord: IAudit }> = ({ auditRecord }) => {
  const { t } = useTranslation('activitiesComponent');
  const { cardTypes } = useCardTypes();
  const { changes, oldData, newData } = auditRecord;

  const fields = useMemo(() => {
    if (!cardTypes) return [];
    const cardTypeId = newData?.cardTypeId || oldData?.cardTypeId;
    return cardTypes.find((item) => item.id === cardTypeId)?.fields ?? [];
  }, [cardTypes, oldData, newData]);

  const changesWithFields = useMemo(() => {
    if (!changes || fields.length === 0) return [];
    return changes
      .map((diff) => {
        const { key } = diff;
        const field = fields.find((field) => field.system === key);
        return { ...diff, field: field as FieldProps };
      })
      .filter(({ field }) => Boolean(field));
  }, [changes, fields]);

  if (changesWithFields.length === 0) return null;

  return (
    <Stack gap="xs">
      {changesWithFields.map((change) => (
        <Group key={change.key} gap={5}>
          <Text size="sm" c={'dimmed'}>
            {t('The field')}
          </Text>
          <Text size="sm" fw={600}>
            {change.field.label}
          </Text>

          {change.from ? (
            <>
              <Text size="sm" c={'dimmed'}>
                {t('was changed from')}
              </Text>
              <FieldDisplayComponent field={change.field} value={change.from} />
            </>
          ) : (
            <Text size="sm" c={'dimmed'}>
              {t('was changed from empty value')}
            </Text>
          )}
          {change.to ? (
            <>
              <Text size="sm" c={'dimmed'}>
                {t('to')}
              </Text>
              <FieldDisplayComponent field={change.field} value={change.to} />
            </>
          ) : (
            <Text size="sm" c={'dimmed'}>
              {t('to empty value')}
            </Text>
          )}
        </Group>
      ))}
    </Stack>
  );
};

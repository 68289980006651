import { Button, Tooltip } from '@mantine/core';
import { useMemo } from 'react';
import { type SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePermission } from '~/Components/Settings/RolesPermissions/permissions.hook';
import { useSettings } from '~/ui/hooks/settings.hook';
import { type Card } from '~db/types';
import { type IExtendedCreateCommunication } from './types';

export const SubmitSmsButton: React.FC<{
  onSubmit: SubmitHandler<IExtendedCreateCommunication>;
  card?: Card;
}> = ({ onSubmit, card }) => {
  const formMethods = useFormContext<IExtendedCreateCommunication>();
  const isSubmitting = formMethods.formState.isSubmitting;
  const sendFromSystem = formMethods.watch('sendFromSystem');
  const { isLoading, canSend, reason } = useSms(card);
  const { t } = useTranslation('activitiesComponent');
  return (
    <Tooltip label={reason} position="bottom">
      <Button
        color="blue"
        loading={isLoading || Boolean(sendFromSystem && isSubmitting)}
        disabled={!canSend}
        onClick={() => {
          formMethods.setValue('sendFromSystem', true);
          void formMethods.handleSubmit(onSubmit)();
        }}
      >
        {t('Send SMS')}
      </Button>
    </Tooltip>
  );
};

export const useSms = (card?: Card) => {
  const { isLoadingPlan, isLoadingSettings, plan, settings } = useSettings();
  const smsPlan = plan?.settings.capabilities.sms;
  const smsSettings = settings?.sms;
  const { hasPermission } = usePermission('SMS', 'create');
  const { t } = useTranslation('activitiesComponent');

  const [canSend, reason] = useMemo<[boolean, string]>(() => {
    if (!smsPlan) return [false, t('No SMS plan found')];
    if (!hasPermission) {
      return [false, t('You do not have permission to send SMS - please contact your administrator')];
    }

    if (!smsPlan.balance || smsPlan.balance <= 0) {
      return [false, t('You have no SMS left in your balance')];
    }
    if (!smsSettings?.sender) {
      return [false, t('Please set up a "sender number" for SMS in Organization Settings')];
    }
    if (!card) {
      return [false, t('No card found, please select a card and try again')];
    }
    if (!card?.phone) {
      return [
        false,
        t('{{name}} does not have a phone number, please add one and try again', { name: card?.name }),
      ];
    }

    return [true, t('You have {{balance}} SMS left', { balance: smsPlan.balance })];
  }, [plan, hasPermission, settings, card]);

  return { isLoading: isLoadingPlan || isLoadingSettings, canSend, reason };
};

import { Button } from '@mantine/core';
import { IconZzz, IconZzzOff } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { type Meeting, type Task } from '~/server/services/activity/types';
import { useSnoozeReminder } from './snooze-reminder.hook';

export const SnoozeReminder: React.FC<{
  activity: Meeting | Task;
  onClick?: () => void;
}> = ({ activity, onClick }) => {
  const { t } = useTranslation('activitiesComponent');
  const { isSnoozable, removeReminder, snooze, isUpdating } = useSnoozeReminder({
    activity,
    onSuccessCallback: onClick,
  });

  if (!activity.reminder) return null;
  if (!isSnoozable) return null;

  return (
    <>
      <Button
        rightSection={<AppIcon Icon={IconZzzOff} size={'1rem'} color="red" />}
        size="xs"
        variant="subtle"
        onClick={removeReminder}
        color="red"
        loading={isUpdating}
      >
        {t('Remove reminder')}
      </Button>
      {activity.reminder < new Date(activity.date.getTime() - 5 * 60 * 1000) && (
        <Button
          rightSection={<AppIcon Icon={IconZzz} size={'1rem'} />}
          size="xs"
          variant="subtle"
          onClick={snooze}
          loading={isUpdating}
        >
          {t('Remind me 5 minutes before')}
        </Button>
      )}
    </>
  );
};

import { Text, Tooltip } from '@mantine/core';
import {
  IconClock,
  IconTimeDuration15,
  IconTimeDuration30,
  IconTimeDuration45,
  IconTimeDuration60,
  IconTimeDuration90,
} from '@tabler/icons-react';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { type TablerIconType } from '~/Components/Shared/Icon/types';
import { type ICreateMeeting } from '~/server/services/activity/types';
import { useDurationOptions } from './duration-options.hook';

const icons: Record<string, TablerIconType> = {
  '15min': IconTimeDuration15,
  '30min': IconTimeDuration30,
  '45min': IconTimeDuration45,
  '1hour': IconTimeDuration60,
  '1hour30min': IconTimeDuration90,
};

export const Duration: React.FC<{ meeting: ICreateMeeting }> = ({ meeting }) => {
  const { endDate, date } = meeting;
  const { option } = useDurationOptions({ endDate, initialDate: date });

  const Icon = option && icons[option.value];

  return (
    <Tooltip label={option?.label}>
      <Text>
        <AppIcon Icon={Icon ?? IconClock} size={'1rem'} />
      </Text>
    </Tooltip>
  );
};

import { api } from '~/trpc';

export const useAutomations = () => {
  const {
    data: automations = [],
    isFetching,
    ...rest
  } = api.automations.search.useQuery(
    { limit: 100 },
    { staleTime: 60 * 60 * 1000, gcTime: 2 * 60 * 60 * 1000 },
  );

  return { automations, isFetching, ...rest };
};

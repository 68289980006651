export const reminderIds = [
  'none',
  '5min',
  '10min',
  '15min',
  '30min',
  '45min',
  '1hour',
  '1day',
] as const;
type ReminderId = (typeof reminderIds)[number];

type ReminderLabel =
  | 'None'
  | '5 minutes before'
  | '10 minutes before'
  | '15 minutes before'
  | '30 minutes before'
  | '45 minutes before'
  | '1 hour before'
  | '1 day before';

export type IReminder = {
  id: ReminderId;
  label: ReminderLabel;
  value: number | null;
};

import { z } from 'zod';
import { meetingSchema } from '~/server/services/activity/types';
import { durationIds } from '../Event/Duration/types';
import { reminderIds } from '../Event/Reminder/types';

export const meetingFormSchema = meetingSchema.extend({
  reminderTimeBefore: z.enum(reminderIds),
  duration: z.enum(durationIds),
});

export type IFormCreateMeeting = z.infer<typeof meetingFormSchema>;

import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '~/trpc';

export const useAutomation = ({ id, onSuccess }: { id: string; onSuccess?: () => void }) => {
  const { t } = useTranslation('automationsPage');

  const utils = api.useUtils();
  const { mutate: deleteAutomation } = api.automations.delete.useMutation({
    async onSuccess() {
      await utils.automations.search.invalidate();
      onSuccess?.();
      notifications.show({
        title: t('Automation deleted'),
        message: t('Automation was successfully deleted'),
        color: 'green',
      });
    },
  });

  const { mutateAsync: upsert, isPending } = api.automations.upsert.useMutation({
    async onSuccess() {
      await utils.automations.search.invalidate();
    },
  });

  const handleDelete = useCallback(() => {
    modals.openConfirmModal({
      title: t('Delete automation'),
      children: t(`Are you sure you want to delete automation?`),
      onConfirm: () => {
        deleteAutomation({ id });
      },
    });
  }, [deleteAutomation, id]);

  return { upsert, handleDelete, isUpdating: isPending };
};

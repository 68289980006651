import { Stack, Text } from '@mantine/core';

export const Description: React.FC<{ title?: string | null; description: string }> = ({
  title = '',
  description = '',
}) => {
  return (
    <Stack gap={0} style={{ marginBottom: 'auto', width: '65%' }}>
      {title && (
        <Text fz={10} lineClamp={1}>
          {/* TODO make sure it looks good fz={10} it was h6 */}
          {title}
        </Text>
      )}
      {description && (
        <Text size="sm" color={'dimmed'} lineClamp={2}>
          {description}
        </Text>
      )}
    </Stack>
  );
};

import { useUser } from '@clerk/nextjs';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import { IconAlarm, IconAlertTriangle, IconClock } from '@tabler/icons-react';
import { useCallback, useEffect, useMemo } from 'react';
import { Form, FormProvider, type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCard } from '~/Components/Card/card.hook';
import { useCardTypes } from '~/Components/Cards/card-types.hook';
import { FormCheckbox } from '~/Components/Form/Checkbox';
import { FormDateTimePicker } from '~/Components/Form/Dates/DateTimePicker';
import { FormInput } from '~/Components/Form/Input/Input';
import { FormCardSelector } from '~/Components/Form/Lookup/FormCardSelectors';
import { FormUserSelect } from '~/Components/Form/Lookup/UserSelect';
import { FormSelect } from '~/Components/Form/Select/Select';
import { SubmitButton } from '~/Components/Form/Submit';
import { FormSwitch } from '~/Components/Form/Switch';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { AiAssistance } from '../../AiAssistance/AiAssitance';
import { useSms } from '../../Communication/SubmitSmsButton';
import { useReminderOptions } from '../Event/Reminder/reminder-options.hook';
import { type IFormCreateTask, taskFormSchema } from './task-schema.hook';
import { useUpsertTask } from './upsert-task.hook';

export const UpsertTask: React.FC = () => {
  const { t } = useTranslation('activitiesComponent');
  const { close, opened, cardId, task, del, upsert, isLoading } = useUpsertTask();
  const { cardTypes = [] } = useCardTypes();
  const { user } = useUser();
  const isNew = !task?.id;

  const defaultValues = useMemo<IFormCreateTask>(
    () => ({
      date: new Date(),
      type: 'TASK',
      done: false,
      assignedTo: user?.id ?? '',
      reminderTimeBefore: '15min',
      reminder: null,
      description: '',
      cardId,
      settings: {
        emailReminder: false,
        smsReminder: false,
      },
    }),
    [user?.id, cardId],
  );
  const formMethods = useForm<IFormCreateTask>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(taskFormSchema),
  });

  const selectedCardId = useWatch({ control: formMethods.control, name: 'cardId' });
  const { card } = useCard(selectedCardId);

  const reminderWatch = formMethods.watch('reminderTimeBefore');
  const dateWatch = formMethods.watch('date');

  const { reminderOptions, option, selectedDate } = useReminderOptions({
    reminder: task?.reminder,
    date: dateWatch,
    initialDate: task?.date,
    selectedId: reminderWatch,
  });

  const onSubmit: SubmitHandler<IFormCreateTask> = useCallback(
    ({ reminderTimeBefore, reminder, ...data }) => {
      upsert({
        ...data,
        reminder: selectedDate,
      });
      close();
    },
    [upsert, selectedDate, close],
  );

  useEffect(() => {
    if (!task) return;
    formMethods.reset(() => {
      return {
        ...task,
        reminderTimeBefore: option?.value ?? 'none',
      };
    });
  }, [task, formMethods, option]);

  const { canSend, reason } = useSms(card);

  return (
    <FormProvider {...formMethods}>
      <Modal
        opened={opened}
        onClose={close}
        title={
          <Stack gap={2}>
            <Text fw="500" size={'lg'}>
              {isNew ? t('Create task') : t('Edit task')}
            </Text>
            {card?.name && (
              <Text size={'sm'} c={'gray'}>
                {t('for {{name}}', { name: card?.name })}
              </Text>
            )}
          </Stack>
        }
        size="xl"
      >
        <Form onSubmit={() => formMethods.handleSubmit(onSubmit)()}>
          <Stack>
            {cardId ? null : (
              <Group>
                <FormCardSelector
                  cardTypes={cardTypes}
                  name="cardId"
                  label={t('Select a card')}
                  description={t('Select the card to which you want to associate this task')}
                />
              </Group>
            )}
            <Group grow>
              <FormDateTimePicker
                required
                name="date"
                label={t('When')}
                dropdownType="modal"
                leftSection={<IconClock size="1rem" />}
              />
              <FormSelect
                name="reminderTimeBefore"
                label={t('Reminder')}
                icon={<IconAlarm size="1rem" />}
                clearable
                data={reminderOptions}
              />
              {task?.done && <FormCheckbox name="done" label={`${t('Is done')}?`} />}
            </Group>
            <FormUserSelect name="assignedTo" label={`${t('Assignee')}?`} required />
            <FormInput name="title" label={t('Title')} />
            <AiAssistance />
            <Group align="baseline">
              {reason && isNew ? (
                <AppIcon tooltip={reason} Icon={IconAlertTriangle} color="orange" />
              ) : null}
              <FormSwitch
                disabled={!canSend || !isNew}
                name="settings.smsReminder"
                label={t('Send SMS reminder and updates')}
                description={t('Send SMS on changes, cancel and reminder')}
              />
            </Group>

            <Group justify="space-between">
              <SubmitButton>{isNew ? t('Create') : t('Update')}</SubmitButton>
              {!isNew && (
                <Button color="red" size="sm" variant="filled" onClick={del}>
                  {t('Delete')}
                </Button>
              )}
            </Group>
          </Stack>
        </Form>
      </Modal>
    </FormProvider>
  );
};

import { Stack, Text } from '@mantine/core';
import { useMemo } from 'react';
import { dateTimeService } from '~/utils/date-time.service';

export const Time: React.FC<{ dueDate: Date }> = ({ dueDate }) => {
  const { day, month, time } = useMemo(() => dateTimeService.timestampAsObject(dueDate), [dueDate]);

  return (
    <Stack justify="space-between" mr={'xl'} align="center" gap={0}>
      <Text size="sm" fw="700">
        {month}
      </Text>
      <Text size={'xl'} fw="700">
        {day}
      </Text>
      <Text size={'xs'} fw="700">
        {time}
      </Text>
    </Stack>
  );
};

export const durationIds = [
  '15min',
  '30min',
  '45min',
  '1hour',
  '1hour15min',
  '1hour30min',
  '1hour45min',
  '2hours',
] as const;

type DurationId = (typeof durationIds)[number];

type DurationLabel =
  | '15 minutes'
  | '30 minutes'
  | '45 minutes'
  | '1 hour'
  | '1 hour and 15 minutes'
  | '1 hour and 30 minutes'
  | '1 hour and 45 minutes'
  | '2 hours';

export type IDuration = {
  id: DurationId;
  label: DurationLabel;
  value: number;
};

import { Stack, Switch, type SwitchProps } from '@mantine/core';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';

type IFormSwitchProps = SwitchProps &
  IBaseFormInputProps & {
    labelInline?: boolean;
  };

export const FormSwitch: React.FC<IFormSwitchProps> = ({ name, label, description, ...props }) => {
  const { control } = useFormContext();
  const {
    field: { value, ...rest },
  } = useController({
    name,
    control,
  });

  return (
    <Stack gap={'sm'} mt={0}>
      <Switch
        label={label ? <Label label={label} description={description} /> : undefined}
        checked={typeof value === 'boolean' ? value : false}
        {...rest}
        {...props}
      />
    </Stack>
  );
};
